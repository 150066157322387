import React from 'react';
import LocationIcon from '../../../static/assets/job/place.svg';
import WorkIcone from '../../../static/assets/job/work.svg';
import HeaderComponent from '../headerComponent';
import { Link } from 'gatsby';
const JobHomeComponent = props => {
  const {
    job,
    jobPlace,
    jobTitle,
    jobDescription,
    applyButton,
    learnButton,
    onLearnMoreClick,
  } = props;
  return (
    <div className='job-home-section'>
      <HeaderComponent />
      <div className='full-container'>
        <div className='job-home-section-wrapper'>
          <div className='job-home-section-location'>
            <LocationIcon className='job-home-section-location-icon' />
            <p className='job-home-section-location-text'>{jobPlace}</p>
            <WorkIcone className='job-home-section-location-icon' />
            <p className='job-home-section-location-text'>{job}</p>
          </div>
          <h1 className='job-home-section-title'>{jobTitle}</h1>
          <p
            className='job-home-section-description'
            dangerouslySetInnerHTML={{
              __html: jobDescription
                ?.replace('Motomtech', 'UpTech Sh.P.K')
                ?.replace('Motomtech’s', 'UpTech Sh.P.K’s')
                ?.replace('LLC', ''),
            }}
          ></p>
          <div className='job-home-section-buton'>
            <Link to='/apply' className='job-home-section-button-apply'>
              {applyButton}
            </Link>
            <button
              className='job-home-section-button-learn'
              onClick={onLearnMoreClick}
            >
              {learnButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobHomeComponent;
