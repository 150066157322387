import React from 'react';
import EmailContact from '../../../static/assets/email.svg';
import PhoneContact from '../../../static/assets/phone.svg';
import { Link } from 'gatsby';
const JobComponent = props => {
  const {
    jobCompanyDescription,
    jobContact,
    jobContactName,
    jobContactPosition,
    jobContactButton,
    learnMoreRef,
  } = props;
  return (
    <div className='job-section' ref={learnMoreRef}>
      <div className='full-container'>
        <p
          className='job-section-description'
          dangerouslySetInnerHTML={{
            __html: jobCompanyDescription
              ?.replaceAll('MotomTech LLC', 'UpTech Sh.P.K')
              ?.replaceAll('Utah, USA', 'Tirana')
              ?.replaceAll(
                'and also has an operational office located in Tirana, Albania',
                ''
              )
              ?.replaceAll('Motomtech', 'UpTech Sh.P.K')
              ?.replaceAll('Motomtech’s', 'UpTech Sh.P.K’s')
              .replace('LLC', ''),
          }}
        ></p>
        <div className='job-section-contact'>
          <p className='job-section-contact-title'>{jobContact}</p>
          <div className='row'>
            <div className='col-md-6'>
              <p className='job-section-contact-name'>{jobContactName}</p>
              <p className='job-section-contact-position'>
                {jobContactPosition}
              </p>
            </div>
            <div className='col-md-6 job-section-col'>
              <div className='job-section-email'>
                <EmailContact className='job-section-email-icon' />
                <p className='job-section-email-text'>imelda@uptechshpk.com</p>
              </div>
              <div className='job-section-email'>
                <PhoneContact className='job-section-email-icon' />
                <p className='job-section-email-text'>(+355) 67-544-6541</p>
              </div>
            </div>
          </div>
          <Link to='/apply' className='job-section-contact-button'>
            {jobContactButton}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default JobComponent;
